<template>
<Spinner  ref="spinner" />
<ol-map ref="map" :loadTilesWhileAnimating="true" :loadTilesWhileInteracting="true" style="height:800px">

    <ol-view ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection" />

    <ol-tile-layer ref="osmLayer" title="OSM" >
        <ol-source-xyz crossOrigin='anonymous' :url="randamTilesURL()" />
   
    </ol-tile-layer>

    
    <ol-control-bar>
        <ol-control-toggle html="<i class='fas fa-map-marker'></i>" className="edit" title="Point" :onToggle="(active)=>changeDrawType(active,'Point')" />
        <ol-control-toggle html="<i class='fas fa-draw-polygon'></i>" className="edit" title="Polygon" :onToggle="(active)=>changeDrawType(active,'Polygon')" />
        <ol-control-toggle html="<i class='fas fa-circle'></i>" className="edit" title="Circle" :onToggle="(active)=>changeDrawType(active,'Circle')" />
        <ol-control-toggle html="<i class='fas fa-grip-lines'></i>" className="edit" title="LineString" :onToggle="(active)=>changeDrawType(active,'LineString')" />
          <ol-control-toggle html="<i class='fas fa-file'></i>" className="edit" title="Export"  :onToggle="(active)=>exportJSON()" />
        <ol-control-videorecorder @stop="videoStopped">

        </ol-control-videorecorder>
       
    </ol-control-bar>

    <ol-mouseposition-control />
    <ol-fullscreen-control />
  
    <ol-overviewmap-control>
        <ol-tile-layer>
            <ol-source-osm />
        </ol-tile-layer>
    </ol-overviewmap-control>

    <ol-scaleline-control />
    <ol-rotate-control />
    <ol-zoom-control />
    <ol-zoomslider-control />

    <ol-context-menu :items="contextMenuItems" />
    <ol-interaction-dragrotatezoom />
    <ol-interaction-clusterselect @select="featureSelected" :pointRadius="20">
        <ol-style>
            <ol-style-stroke color="green" :width="5"></ol-style-stroke>
            <ol-style-fill color="rgba(255,255,255,0.5)"></ol-style-fill>
            <ol-style-icon :src="markerIcon" :scale="0.05"></ol-style-icon>
        </ol-style>
    </ol-interaction-clusterselect>

    <ol-interaction-select @select="featureSelected" :condition="selectCondition" :filter="selectInteactionFilter" v-if="!drawEnable">
        <ol-style>
            <ol-style-stroke color="green" :width="10"></ol-style-stroke>
            <ol-style-fill color="rgba(255,255,255,0.5)"></ol-style-fill>
            <ol-style-icon :src="markerIcon" :scale="0.05"></ol-style-icon>
        </ol-style>
    </ol-interaction-select>

     <ol-vector-layer title="fill" >
        <ol-source-vector ref="defsource"  :url="toURL(defaultGeoJson)" :format="geoJson" :projection="projection">

            <ol-interaction-modify v-if="drawEnable" @modifyend="modifyend" @modifystart="modifystart">
            </ol-interaction-modify>
            <ol-interaction-draw v-if="drawEnable" :type="drawType" @drawend="drawend" @drawstart="drawstart">
            </ol-interaction-draw>
            <ol-interaction-snap v-if="drawEnable" />
            
            <ol-style>
            <ol-style-stroke color="red" :width="2"></ol-style-stroke>
            <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
            <ol-style-circle :radius="7">
                <ol-style-fill color="blue"></ol-style-fill>
            </ol-style-circle>
        </ol-style>
        
        </ol-source-vector> 
       
    </ol-vector-layer>


  <ol-vector-layer  ref="source"  title="jsonfill"  v-for ="(geoJsonObject) in geoJsonList" :key="geoJsonObject">
        <ol-source-vector  :url="toURL(geoJsonObject.geo)" :format="geoJson" :projection="projection">

            <ol-style>
            <ol-style-stroke color="red" :width="2"></ol-style-stroke>
            <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
            <ol-style-circle :radius="7">
                <ol-style-fill color="blue"></ol-style-fill>
            </ol-style-circle>
          </ol-style>

      <template v-for="feature in geoJsonObject.geo.features" :key="feature">
      
            <ol-overlay   :position="getCenter(feature)" v-if="geoJsonObject.options.showContent">
                    <div :class="'featureid-'+feature.id" class="overlay-content balloon">
                <p v-on:click="disableBaloon( 'featureid-'+ feature.id,feature )" style="text-align: right; cursor: pointer;"  > X </p>
        
                <p v-if="feature.properties.title" v-html="feature.properties.title"> </p>
                <p v-if="feature.properties.description" v-html="feature.properties.description"> </p>
           </div>
         
    </ol-overlay>        
           </template>
        </ol-source-vector>
    </ol-vector-layer>
</ol-map>
<div v-html="this.tileSource"> </div>
</template>

<script>

import {
    ref,
    inject,
    onMounted
} from 'vue'


import markerIcon from '@/assets/marker.png'
import Spinner from "../Spinner.vue";
import moment from "moment";


export default {
 props:[ "path", "Sql"]  ,
  components:  { Spinner },
   data (){
       return {
          center : ref([135.493  , 34.728,]), 
         zoom : ref(8),
         rotation : ref(0),
        defaultGeoJson  : { },
 geoJsonList : [ ],
          tilesURL:"/osm-{s}/{z}/{x}/{y}.png",
       //  tilesURL : "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" , 
         tileSource : "" 
       };
   } ,
   beforeUpdate (){
   },
   async mounted(  ) {
     
        //   await this.fetchData();
     
        if ( ! window.isCrossOrigin ){
      
      await this.showData(await window.parent.getSql() ,this.path);   
     } else {

      await this.showData(await window.getSql() ,this.path);   
     }
    },
    setup() {
        
        const projection = ref('EPSG:4326')
      
        const format = inject('ol-format');
        
        const geoJson = new format.GeoJSON( );

        const selectConditions = inject('ol-selectconditions')

        const selectCondition = selectConditions.pointerMove;

        const selectedName = ref('')
        const selectedPosition = ref([])
        const extent = inject('ol-extent');

        const Feature = inject('ol-feature')
        const Geom = inject('ol-geom')

        const contextMenuItems = ref([])
        const vectorsource = ref(null)
        const view = ref(null);

        const drawEnable = ref(false)
        const drawType = ref("Point")

        const changeDrawType = (active, draw) => {
            drawEnable.value = active
            drawType.value = draw
        }

        contextMenuItems.value = [{
                text: 'Center map here',
                classname: 'some-style-class', // add some CSS rules
                callback: (val) => {
                    
                    view.value.setCenter(val.coordinate)
                } // `center` is your callback function
            },
            '-' // this is a separator
        ]
        const featureSelected = (event) => {
           try{ 
            if (event.selected.length == 1) {
               var fe = event.selected[0];
           
               const elements =  window.document.getElementsByClassName (  "featureid-" +fe.id_ );
              
                for(var i=0;i<elements.length;i++){
                    elements[i]  .style.display ="inline-block";
                }
                // selectedPosition.value = extent.getCenter(event.selected[0].getGeometry().extent_)
               //  selectedName.value = event.selected[0].values_.title;
            } else {
               // selectedName.value = '';
            }
          }catch  (e){
              
              console.log(e);
          }
        }

        const selectInteactionFilter = (feature) => {
           //  return feature.values_.name;
             return feature.values_.name != undefined;
        };

        const drawstart = (event) => {
            console.log(event)

        }

        const drawend = (event) => {
            console.log(event)
             //var geoJsonStr = geoJson.writeFeatures(event.feature);
             //  console.log(geoJsonStr)

        }

        const modifystart = (event) => {
            console.log(event)

        }

        const modifyend = (event) => {
            console.log(event)
        }

        const videoStopped = (event) => {
            console.log(event)
        }

        const swipeControl = ref(null)
        const osmLayer = ref(null)
        const layerList = ref([])
    

        onMounted(() => {

           // layerList.value.push(jawgLayer.value.tileLayer);
            layerList.value.push(osmLayer.value.tileLayer);
            console.log(layerList.value)
        });
        return {
            projection,
            geoJson , 
            featureSelected,
            selectCondition,
            selectedName,
            selectedPosition,
            markerIcon,
            contextMenuItems,
            vectorsource,
            view,
            selectInteactionFilter,
            drawstart,
            drawend,
            modifystart,
            modifyend,
            videoStopped,
            drawEnable,
            drawType,
            layerList,
            swipeControl,
            osmLayer,
            changeDrawType
        }
    },
     methods:{
    randamTilesURL() {
    // {s} を a, b, c のどれかにランダムで選ぶ
    const letters = ['a', 'b', 'c'];
    const randomLetter = letters[Math.floor(Math.random() * letters.length)];
    
    // {s} をランダムな文字に置き換える
    return this.tilesURL.replace('{s}', randomLetter);
},
      exportJSON(){
          for(var i =0; i <this.geoJsonList.length  ; i++  ){
            try{
                const text = JSON.stringify(this.geoJsonList[i].geo );       
                const blob = new Blob([text], { type: 'text/plain' });
                const aTag = document.createElement('a');
                aTag.href = URL.createObjectURL(blob);
                aTag.target = '_blank';
                aTag.download = "export-"+i+".json";
                aTag.click();
                URL.revokeObjectURL(aTag.href);    
            } catch (e){
                console.log(e);
            }
          }
         
      },
      getCenter(fe){
          var coor ;
          
          if (fe.geometry.type==="Polygon" ){
            coor = fe.geometry.coordinates[0];
          }else {
              coor  = fe.geometry.coordinates;
          }
          const extent = inject('ol-extent');
          var coors = [];       
          for( var i  = 0  ;  i < coor.length ; i++ ){

              coors.push(coor[i][0]);
                coors.push(coor[i][1]);
                if( coor.length == 1){
                     coors.push(coor[i][0]);
                    coors.push(coor[i][1]);
                }
                
            }
        
           const center =  extent.getCenter(coors);
           return center;
      },
      disableBaloon(id,fu){
        const elements = window.document.getElementsByClassName(id);
      
          for(var i=0;i<elements.length;i++){
            elements[i]  .style.display ="none";
        }
      },
      toURL (obj){
          const url = URL.createObjectURL(new Blob([JSON.stringify(obj)] , {type:'text/plain'}) ) ; 
          // alert(url);
          return ref(url );
       } ,
       async showData ( Sql,path){
       try{
          if(!path){
              path =this.path;
          }
          path = encodeURI(path);
          path = decodeURI(path);
          
          path = path.replaceAll (  '+', '%2B' )
          const p=path.split('/?');
          
         const param = new URLSearchParams(  "?" + p[1] );
         var  q = param.get('q') ;
      
      
        const particleSize  = param.get('particle_size')  ;
        const  aggregate  = param.get('agg');
        const startDate   = param.get('start')  ;
        const  endDate  = param.get('end');

        q = q.replaceAll("{START_DATE}", startDate );    
        q = q.replaceAll("{END_DATE}",endDate);   
        q = q.replaceAll("{PARTICLE_SIZE}", particleSize);
        q = q.replaceAll("{AGGREGATE}",  aggregate );   
        q = q.replaceAll("{TEMPORAL_GRANULARITY}", particleSize );
        q = q.replace("{TODAY}",  moment().format('YYYY-MM-DD') );   
        q = q.replace("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
   
          while(this. geoJsonList.pop());

         
         Sql.executeAsyncQueue( q , (res)=>{ 
                const results = [];
                for( let i = 0 ; i < res.length ; i++ )  {
                    const resp = res[i];
                    const rows = [];   
                    for ( let j= 0 ;  j <  resp.values.length ; j++  ){
              
                          let o = {};  
                          for (let k=0;  k < resp.columns.length ; k++ ){
                 
                            o[ resp.columns[k] ] = resp.values[j][k];   
                             
                            }
                           rows.push( o );   
                      }
                      results.push( rows );                     
                }
                
                // console.log(results);
                var geojsonFeature =  null ; 
                var Options = {} ;
                  
                for ( let i=0 ; i < results.length ; i ++ ){
                    
                    const resul = results[i] ;
                    for (let j =0;  j < resul .length  ; j++){
                    
                        if( resul[ j ].OptionShowContent  ){
                             Options.showContent = true;
                        }
                        
                        if( resul[ j ].Type === "FeatureGeometry" ){
                    
                            if ( resul[ j ].GeometoryType==="Point"  || geojsonFeature === null){ 
                                          	
                                const crs = { properties : {}  };
                    
                            if ( resul[ j ].CrsType ){
                          
                               resul[ j ].CrsType  
                            }
                          
                            if ( resul[ j ].CrsPropertieName ){
                           
                                crs.name  =  resul[ j ].CrsPropertieName; 
                            }
                         
                            if ( resul[ j ].CrsPropertieName ){
                           
                               crs.name  =  resul[ j ].CrsPropertieName; 
                            } 
                        
                            if ( resul[ j ].CrsPropertieName ){
                           
                                crs.properties.name  =  resul[ j ].CrsPropertieName; 
                            }
                        
                            //if(!geojsonFeature){
                                geojsonFeature = {
                                "id" : i +"-"+j,
                                "type":"Feature",
                                "crs" : crs , 
                                "properties" :{},
                                "geometry" : 
                                    {"type" :  (resul[ j ].GeometoryType==="Point")? "MultiPoint" : resul[ j ].GeometoryType , 
                                    "coordinates" : []
                                    }     
                                };
                            //}
                            if ( resul[ j ].FeatureID ){
                        
                                geojsonFeature.id = resul[ j ].FeatureID;
                            }   
                            if ( resul[ j ].PropertieTitle ){
                              
                                geojsonFeature.properties.title = resul[ j ].PropertieTitle;  
                            }
                            if ( resul[ j ].PropertieDescription ){
                              
                                geojsonFeature.properties.description = resul[ j ].PropertieDescription;       
                            }
                            if ( resul[ j ].PropertieMarkerSize ){
                              alert(resul[ j ].PropertieMarkerSize);
                                   geojsonFeature.properties["marker-size"] = resul[ j ].PropertieMarkerSize;
                                   
                            }
                            if ( resul[ j ].PropertieMarkerSymbol ){
                              
                                geojsonFeature.properties["marker-symbol"] = resul[ j ].PropertieMarkerSymbol;
                            }
                            if ( resul[ j ].PropertieMarkerColor ){
                              
                                geojsonFeature.properties["marker-color"] = resul[ j ].PropertieMarkerColor;        
                            }
                            if ( resul[ j ].PropertieStroke ){
                              
                                geojsonFeature.properties.stroke = resul[ j ].PropertieStroke;        
                            }
                            if ( resul[ j ].PropertieStrokeWidth){
                              
                                geojsonFeature.properties["stroke-width"] = resul[ j ].PropertieStrokeWidth;  
                            }
                            if ( resul[ j ].PropertieFill ){
                              
                                geojsonFeature.properties.fill = resul[ j ].Fill;        
                            }
                            if ( resul[ j ].PropertieFillColor ){
                              
                                geojsonFeature.properties["fill-color"] = resul[ j ].PropertieFillColor;        
                            }
                          
                            if( resul[ j ].GeometoryType === "Polygon" ){
                          
                                geojsonFeature.geometry.coordinates.push( [ [ resul[ j ].GeometoryCoordinateLongitude , resul[ j ].GeometoryCoordinateLatitude ]] );
                            } else {
                             
                                geojsonFeature.geometry.coordinates.push(  [ resul[ j ].GeometoryCoordinateLongitude , resul[ j ].GeometoryCoordinateLatitude ]) ;    
                            }

                         
                         //  const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");
                        
                         // this.mapIsReady = true;
    
                        } else {
                        
                            if( resul[ j ].GeometoryType === "Polygon" ){
                             
                                geojsonFeature.geometry.coordinates[0].push( [ resul[ j ].GeometoryCoordinateLongitude , resul[ j ].GeometoryCoordinateLatitude ] );
                              
                            } else {
                            
                                geojsonFeature.geometry.coordinates.push( [ resul[ j ].GeometoryCoordinateLongitude , resul[ j ].GeometoryCoordinateLatitude ] );
                            }
                        }
                                 
                    } else
                    if( resul[ j ].Option === "map" ) {
                       
                        if ( resul[j].TileURL ) {
                            this.tilesURL = resul[j].TileURL;
                        }
                        if( resul[j].TileSource ){
                              this.tileSource = resul[j].TileSource;
                        }
                        if(resul[j].CenterLongitude && resul[j].CenterLatitude ){
                             this.center = ref( [  resul[j].CenterLongitude, resul[j].CenterLatitude ]  );    
                        }
                        if (resul[j].Zoom){
                               
                            this.zoom = ref (resul[j].Zoom )      
                        }
                        if (resul[j].Rotation){
                               
                            this.rotation = ref (resul[j].Rotation )      
                        }
                    }
                   
                }
                   if (geojsonFeature  ) {
                        
                        const geo =  { "type": "FeatureCollection", "features": [ geojsonFeature ] };
                       
                   this. geoJsonList.push( 
                         { options : Options, 
                         geo :geo }
                    );
                    
                }
                 
            }
             this.$refs.spinner.show(false);
              });
          this.$refs.spinner.show(true);
          await Sql.executeAsync();
      
       }catch (e){
           console.log(e);  
        }
    }
    }
}
</script>
<style>
.balloon {
    position: fixed;
    left: -80px;
    display: inline-block;
    padding: 7px 10px;
    bottom: 40px;
    width: 160px;
    color: #555;
    font-size: 16px;
    background: #FFF;
    box-sizing: border-box;
}

.balloon:before {
  content: "";
  position: absolute;
  bottom: -24px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid #FFF;
  z-index: 2;
}

.balloon p {
  margin: 0;
  padding: 0;
}
</style>


